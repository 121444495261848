<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="妃を選びます" />
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab title="都市の一覧です">
					<div class="card">より多くのリソースの予約についてお問い合わせが必要な場合は、上司の受付までご連絡ください</div>
					<div class="address">
						<van-row type="flex" justify="" gutter="65" v-for="(val, key) in addlist" :key="key">
							<van-col span="" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row>
					</div>
				</van-tab>
				<van-tab title="プロセス価格です">
					<div class="card">より多くのリソースの予約についてお問い合わせが必要な場合は、上司の受付までご連絡ください</div>
					<div class="rig-box">
						<p class="rig-title">どんな資源があるのでしょうか?</p>
						<p class="rig-content">網紅、モデル、スチュワーデス、若い模、大学生、あなただけ思いつかなくて、できないプラットフォームがありません</p>
						<p class="rig-title">サービス範囲ですか?</p>
						<p class="rig-content">同城では無料で大砲を予約して、どこでも全国から空輸して、国内の第一、第二線の都市はすべて現地にあります。第三線の都市も受付員に連絡して手配を予約することができます。</p>
						<p class="rig-content">より多くのリソースの予約についてお問い合わせが必要な場合は、上司の受付までご連絡ください</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: 'とうきょう',
					1: 'おおさか',
					2: 'きょうと',
					3: 'ほっかいどう'
				},
				{
					0: 'おきなわ',
					1: 'なごや',
					2: 'ふくおか',
					3: 'よこはま'
				}
			]
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg, #7e5678, #e6c3a1);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 90%;
	margin: 0 auto;
}
</style>
